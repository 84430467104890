// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first-available {
  display: flex;
  gap: 1rem;
  max-width: 23rem;
  justify-content: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
}

.first-available .first-available-date {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/FirstAvailable/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,gBAAgB;EAChB,uBAAuB;EACvB,oBAAoB;EACpB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":[".first-available {\n  display: flex;\n  gap: 1rem;\n  max-width: 23rem;\n  justify-content: center;\n  padding-left: 1.5rem;\n  padding-right: 1.5rem;\n  padding-top: 0.5rem;\n}\n\n.first-available .first-available-date {\n  align-items: center;\n  display: inline-flex;\n  flex-direction: column;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

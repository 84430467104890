// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datetime-select {
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.datetime-select .body {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 32px;
}

.datetime-select .date-selection {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
}

.datetime-select .time-selection {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/screens/DatetimeSelect/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,uBAAuB;EACvB,WAAW;AACb","sourcesContent":[".datetime-select {\n  align-items: center;\n  background-color: #ffffff;\n  border-radius: 12px;\n  display: flex;\n  flex-direction: column;\n}\n\n.datetime-select .body {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n  padding: 32px;\n}\n\n.datetime-select .date-selection {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  justify-content: center;\n}\n\n.datetime-select .time-selection {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  justify-content: center;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

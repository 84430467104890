// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation {
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.confirmation .body-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
}

.confirmation .confirmation-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
  width: 100%;
}
.confirmation .closing-details {
  margin-top: 16px;
  margin-bottom: 0;
}

/* ATC BUTTON */
add-to-calendar-button.atcb-light {
  display: flex;
  justify-content: center;
  width: 100%;
  --base-font-size-l: 0.875rem;
  --base-font-size-m: 0.875rem;
  --base-font-size-s: 0.875rem;
  --btn-background: #fff;
  --btn-text: var(--intelibly-branding-primary-700);
  --btn-border: var(--intelibly-branding-primary-700);
  --font: Inter, Helvetica, sans-serif;
  --btn-text-hover: none;
  --btn-shadow: none;
  --btn-shadow-hover: none;
  --btn-shadow-active: none;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Confirmations/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA,eAAe;AACf;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,4BAA4B;EAC5B,4BAA4B;EAC5B,4BAA4B;EAC5B,sBAAsB;EACtB,iDAAiD;EACjD,mDAAmD;EACnD,oCAAoC;EACpC,sBAAsB;EACtB,kBAAkB;EAClB,wBAAwB;EACxB,yBAAyB;AAC3B","sourcesContent":[".confirmation {\n  align-items: center;\n  background-color: #ffffff;\n  border-radius: 12px;\n  display: flex;\n  flex-direction: column;\n}\n\n.confirmation .body-2 {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  padding: 32px 0px;\n}\n\n.confirmation .confirmation-details {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-bottom: 40px;\n  width: 100%;\n}\n.confirmation .closing-details {\n  margin-top: 16px;\n  margin-bottom: 0;\n}\n\n/* ATC BUTTON */\nadd-to-calendar-button.atcb-light {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  --base-font-size-l: 0.875rem;\n  --base-font-size-m: 0.875rem;\n  --base-font-size-s: 0.875rem;\n  --btn-background: #fff;\n  --btn-text: var(--intelibly-branding-primary-700);\n  --btn-border: var(--intelibly-branding-primary-700);\n  --font: Inter, Helvetica, sans-serif;\n  --btn-text-hover: none;\n  --btn-shadow: none;\n  --btn-shadow-hover: none;\n  --btn-shadow-active: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.calendar-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.arrow-button-container {
  display: flex;
  gap: 16px;
  justify-content: space-between;
}

.calendar ul,

/* TODO: Apply a grid-gap of 0.5em for a mobile breakpoint of 390 */
.calendar ol {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 1em;
  margin: 0 auto;
  padding: 0;
}

.calendar li {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin-left: 0;
}

.calendar ul.weekdays {
  margin-bottom: 1em;
}

ul.weekdays abbr[title] {
  border: none;
  text-decoration: none;
}

@media (max-width: 390px) {
  .calendar ol {
    grid-gap: 0.5em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Calendar/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,aAAa;EACb,SAAS;EACT,8BAA8B;AAChC;;AAEA;;;;EAIE,aAAa;EACb,qCAAqC;EACrC,aAAa;EACb,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".calendar {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.calendar-header {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.arrow-button-container {\n  display: flex;\n  gap: 16px;\n  justify-content: space-between;\n}\n\n.calendar ul,\n\n/* TODO: Apply a grid-gap of 0.5em for a mobile breakpoint of 390 */\n.calendar ol {\n  display: grid;\n  grid-template-columns: repeat(7, 1fr);\n  grid-gap: 1em;\n  margin: 0 auto;\n  padding: 0;\n}\n\n.calendar li {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  list-style: none;\n  margin-left: 0;\n}\n\n.calendar ul.weekdays {\n  margin-bottom: 1em;\n}\n\nul.weekdays abbr[title] {\n  border: none;\n  text-decoration: none;\n}\n\n@media (max-width: 390px) {\n  .calendar ol {\n    grid-gap: 0.5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* Allows for sticky footer */
  background-color: #ffffff;
}

.content-container {
  flex-grow: 1; /* Allows for sticky footer */
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB,EAAE,6BAA6B;EAChD,yBAAyB;AAC3B;;AAEA;EACE,YAAY,EAAE,6BAA6B;EAC3C,WAAW;AACb","sourcesContent":[".layout-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  min-height: 100vh; /* Allows for sticky footer */\n  background-color: #ffffff;\n}\n\n.content-container {\n  flex-grow: 1; /* Allows for sticky footer */\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booking-form-default {
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.booking-form-default .body {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 32px 0px;
}

.booking-form-default .datetime-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.booking-form-default .patient-form-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px 0px 48px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/screens/BookAppoinment/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,qBAAqB;EACrB,WAAW;AACb","sourcesContent":[".booking-form-default {\n  align-items: center;\n  background-color: #ffffff;\n  border-radius: 12px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.booking-form-default .body {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n  padding: 32px 0px;\n}\n\n.booking-form-default .datetime-section {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  width: 100%;\n}\n\n.booking-form-default .patient-form-section {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding: 0px 0px 48px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
